import React, { useEffect, useState } from "react";
import hat from "../../assets/landpage/captain hat-min.png";
import people from "../../assets/landpage/edited 1.png";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";

// import { Link } from 'react-router-dom';
import LandHeader from "../LandingHeader/LandHeader";
import { decodeJWT, getAllAffiliateUser, getCaptainBeeByEmail, loginWithToken } from "../../services/api";
import { useTheme } from "@emotion/react";
import ContactUs from "../ContactUs/ContactUs";
import { Link, useLocation } from "react-router-dom";
import "../CaptainBees/CaptainBees.css";
import VideoSlider from "./VideoSlider";
import { makeStyles } from "@mui/styles";
import hiveBg from "../../assets/updated/hive-front-page/hive-img.svg";
import hiveLogo from "../../assets/updated/hive-front-page/hive-logo.svg";
import logo1 from "../../assets/updated/hive-front-page/logo1.svg";
import logo2 from "../../assets/updated/hive-front-page/logo2.svg";
import logo3 from "../../assets/updated/hive-front-page/logo3.svg";
import FAQ from "./Faq";
import SubscribeForm from "../Subscribe";
import { useNavigate } from "react-router-dom";

import leftBg from "../../assets/Homepage/NewYearleftBg.png";
import rightBg from "../../assets/Homepage/NewYearRightBg.png";
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1980px",
    margin: "80px auto",
    width: "100%",
    padding: "20px",
  },

  heroHeaderContainer: {
    display: "flex",
    gap: "40px",
    maxWidth: "1280px",
    width: "100%",
    padding: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  faqContainer: {
    maxWidth: "1280px",
    width: "100%",
    margin: "100px auto",
    padding: "20px",
  },
  imgContainer: {
    width: "90%",
    margin: "auto",
    "& img": {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
      margin: "auto",
      "& img": {
        width: "100%",
      },
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      alignItems: "center",
    },
  },
  heading: {
    fontSize: "74px",
    fontWeight: "bold",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "52px",
    },
  },
  description: {
    fontSize: "18px",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  buttonContainer: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#FFB300",
    borderRadius: "2px",
    color: "#282828",
    py: 1,
    textTransform: "none",
    fontSize: "13px",
    fontWeight: "600",
    boxShadow: "none",
    mt: 2,
    "&:hover": {
      backgroundColor: "#FFD000",
      boxShadow: "none",
    },
  },

  howItWorksContainer: {
    maxWidth: "1280px",
    width: "100%",
    margin: "250px auto 100px auto",
    padding: "20px",
  },
  howItWorksheading: {
    width: "100%",
    margin: "100px 0px 30px 0px !important",
    fontSize: "40px !important",
    fontWeight: "600 !important",
    color: theme.palette.text.primary,
    textAlign: "left",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr ",
    },
  },

  card: {
    flex: "1 1 30%", // Adjust the basis value to control how many cards are shown per row
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "24px",
    textAlign: "left",
  },
  logo: {
    // Add your logo styles here
    width: "96px",
    height: "96px",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  cardHeading: {
    marginTop: "24px !important",
    marginBottom: theme.spacing(1),
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: theme.palette.text.primary,
  },
  cardText: {
    fontSize: "14px !important",
    marginTop: "8px !important",
    color: theme.palette.text.secondary,
  },
  startNowRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "200px auto",
    "& h2": {
      fontSize: "62px",
      fontWeight: "600",
      color: theme.palette.text.primary,
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        fontSize: "42px",
        margin: "150px auto",
      },
    },
  },
  leadContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "600px",
    margin: "100px auto",
    padding: "10px",
    alignItems: "center",
    flexDirection: "column",
    "& img": {
      height: "150px",
      margin: "auto",
      marginBottom: "20px",
    },
    "& h3": {
      fontSize: "52px",
      fontWeight: "600",
      marginBottom: "20px",
    },
    "& p": {
      fontSize: "16px",
      marginBottom: "20px",
      color: theme.palette.text.secondary,
    },
  },
}));

const First = () => {
  // const [captainbees, setCaptainBees] = useState();
  const classes = useStyles();
  const navigate = useNavigate();
  const [topRatedCbees, setTopRatedCBees] = useState();
  const [themes, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "light"
  );
  const location = useLocation();
  const defaultSignInToken = new URLSearchParams(location.search).get('signInToken');

  useEffect(() => {
    const redirectFlag = localStorage.getItem('redirected');
    if (defaultSignInToken && !redirectFlag) {
      checkLogin(defaultSignInToken);
    }
  }, [defaultSignInToken]);

  const checkLogin = async (signInToken) => {
    try {
      const res = await loginWithToken(signInToken);
      if (res.status === 200) {
        const resObj = await decodeJWT(res.data.access_token);
        localStorage.setItem('email', resObj?.email);
        localStorage.setItem('user', resObj?.email);
        localStorage.setItem('access_token', res.data.access_token);
        localStorage.setItem('refresh_token', res.data.refresh_token);
        localStorage.setItem('userType', resObj?.userType);
        localStorage.setItem('redirected', 'true');

        if (resObj?.userType === 'CaptainBee') {
          const resObj2 = await getCaptainBeeByEmail(resObj?.email);
          const username = resObj2?.data.Username;
          localStorage.setItem('username', username);
        }
        window.location.reload();
      } else {
        console.error('Login failed:', res.data);
      }
    } catch (err) {
      console.error('Error during login:', err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    getAllAffiliateUser().then((data) => {
      // Define the names of the users you want to filter
      const targetNames = [
        "Queen Bee",
        "Grandma Honey",
        "Golden Sea Sailing",
        "Blockmichael22",
      ];

      // Map over targetNames to preserve the order and find corresponding users
      const orderedFilteredUsers = targetNames
        .map((name) => data.find((user) => user.accname === name))
        .filter((user) => user !== undefined); // Filter out undefined entries (if any user is not found)

      // Set the ordered filtered users to state
      setTopRatedCBees(orderedFilteredUsers);
    });
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // console.log(isMobile);

  return (
    <>
      <LandHeader />
      <Box mt={10} justifyContent={"center"} style={{ position: "relative" }}>
        <Box
          sx={{
            position: "relative",
            zIndex: "2",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <Box className={classes.heroHeaderContainer}>
            <div className={classes.textContainer}>
              <img src={hiveLogo} />
              <p className={classes.heading}>indexx Hive</p>
              <p className={classes.description}>
                A community-driven platform connecting experienced crypto
                investors, Hive Captains, with newcomers, Hive Members, offering
                mentorship, guidance, and investment opportunities.
              </p>
              <Box className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  disableTouchRipple
                  className={classes.outlineButton}
                  onClick={() => navigate("/hive-captain")}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    Learn More
                  </span>
                </Button>
                <Button
                  variant="contained"
                  disableTouchRipple
                  className={classes.button}
                  onClick={() => navigate("/sign-up")}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    Join as Hive Captain
                  </span>
                </Button>
              </Box>
            </div>
            <div className={classes.imgContainer}>
              <img src={hiveBg} />
            </div>
          </Box>
        </Box>

        <h1
          style={{
            margin: "70px 0px 20px 0px",
            fontSize: "62px",
            fontWeight: "bold",
          }}
        >
          Happy Family
        </h1>
        <p
          style={{
            margin: "0px 0px 70px 0px",
            fontSize: "16px",
          }}
        >
          From Indexx Investors
        </p>
        <VideoSlider />
        <Box className={classes.howItWorksContainer}>
          <Typography className={classes.howItWorksheading}>
            What’s in the Hive
          </Typography>

          <div className={classes.gridContainer}>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo1} />
              </div>
              <Typography className={classes.cardHeading}>
                1. Hive Captain
              </Typography>
              <Typography className={classes.cardText}>
                Crypto Hive Captains guide members with mentoring, workshops,
                and curated strategies, empowering secure, informed, and
                profitable crypto investments.
              </Typography>
            </Box>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo2} />
              </div>
              <Typography className={classes.cardHeading}>
                2. Hive Member
              </Typography>
              <Typography className={classes.cardText}>
                Actively trade, collaborate, and learn within the Indexx Hive,
                leveraging Hive Captains' expertise to grow their portfolios.
              </Typography>
            </Box>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo3} />
              </div>
              <Typography className={classes.cardHeading}>
                3. Benifits
              </Typography>
              <Typography className={classes.cardText}>
                Hive ensures decentralized control, rewards with cryptocurrency,
                and empowers users through governance, freedom, and earning
                opportunities.
              </Typography>
            </Box>
          </div>
        </Box>

        <Box className={classes.faqContainer}>
          <Typography className={classes.howItWorksheading}>FAQs</Typography>
          <FAQ />
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",

            maxWidth: "1100px",
            margin: "100px auto",
            padding: "0px 50px",
          }}
        >
          <div
            style={{ flex: 1, justifyContent: "flex-start", display: "flex" }}
          >
            <img
              style={{
                width: "80%",
                marginRight: "auto",
              }}
              alt=""
              src={hat}
            />
          </div>
 

          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "15px" : "20px"}
              fontWeight={400}
              textAlign={"left"}
              mb={isMobile ? 0 : "-20px"}
            >
              Why choose a
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "40px" : "80px"}
              fontWeight={700}
              textAlign={"left"}
              ml={"-4px"}
            >
              Hive Captain
            </Typography>

            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"28px"}
              textAlign={"left"}
              // width={"92%"}
              width={isMobile ? "auto" : "447.81px"}
            >
              By choosing a Hive Captain, you gain a dedicated partner who is
              committed to nurturing your financial growth, ensuring that you
              not only embark on a journey of financial exploration but also
              stand to reap the rewards of well-informed decisions. In the vast
              realm of possibilities within the Indexx Hive, a Hive Captain is
              the compass that directs you toward your desired destination of
              investment success.
            </Typography>

            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#FFB300",
                borderRadius: "2px",
                color: "#282828",
                width: `${isMobile ? "100%" : "280px"}`,
                px: 3,
                py: 1,
                textTransform: "none",
                fontSize: `${isMobile ? "9px" : "13px"}`,
                fontWeight: 600,
                boxShadow: "none",
                mt: 3,
                "&:hover": {
                  backgroundColor: "#FFD000",
                  boxShadow: "none",
                },
              }}
            >
              <a
                href="/bees"
                style={{
                  textDecoration: "none", // Set textDecoration to "none" here
                  color: "#282828", // Add the color style for the anchor tag
                }}
              >
                Select your Hive Captain now!
              </a>
            </Button>
          </Box>
        </Box> */}

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 35,
            // maxWidth:"1520px"
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              mb: 4,
              maxWidth: "1520px",
            }}
            alt=""
            src={people}
          />
          <Box
            // width={"55%"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // ml: isMobile ? 0 : 3,
              width: `${isMobile ? "60%" : "100%"}`,
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "14.5px" : "50px"}
              fontWeight={500}
              textAlign={"left"}
            >
              Elevate Your Investment Experience
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "12px" : "13px"}
              fontWeight={400}
              textAlign={"left"}
              mt={2}
              mb={2}
            >
              Join now to handpick your Hive Captain and begin a seamless
              journey to financial success.
            </Typography>

            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#FFB300",
                borderRadius: "2px",
                color: "#282828",
                // px:`${isMobile ? 6.1 : 10}`,
                px: isMobile ? "auto" : 10,
                width: `${isMobile ? "100%" : "auto"}`,
                py: 1,
                textTransform: "none",
                fontSize: `${isMobile ? "9px" : "13px"}`,
                fontWeight: "700",
                boxShadow: "none",
                mt: 3,
                "&:hover": {
                  backgroundColor: "#FFD000",
                  boxShadow: "none",
                },
              }}
            >
              <a
                href="/bees"
                style={{
                  textDecoration: "none", // Set textDecoration to "none" here
                  color: "#282828", // Add the color style for the anchor tag
                }}
              >
                Select your Hive Captain now!
              </a>
            </Button>
          </Box>
        </Box> */}
        {/* <ContactUs /> */}

        <SubscribeForm />
      </Box>
    </>
  );
};

export default First;
