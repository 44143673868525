import React, { useState, useEffect } from "react";
import earn from "../../assets/franchise/Group 1318.svg";
import earn_dark from "../../assets/franchise/earn_dark.svg";
import earnings_mobile from "../../assets/franchise/earnings_mobile.svg";
import earnings_mobile_dark from "../../assets/franchise/earnings_mobile_dark.svg";
import leader from "../../assets/franchise/Leadership.png";
import whale from "../../assets/franchise/Whale.png";
import first_banner from "../../assets/franchise/header graphic.png";
import community from "../../assets/franchise/people 4.svg";
import community_dark from "../../assets/franchise/community_dark.svg";
import community_mobile from "../../assets/franchise/community_mobile.svg";
import community_mobile_dark from "../../assets/franchise/community_mobile_dark.svg";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
// import { Link } from 'react-router-dom';
import Bees from "../CaptainBees/CaptainBees";
// import HoneyComb from "../HoneyComb/HoneyComb";
import SubHeader from "../SubHeader/SubHeader";
import { useTheme } from "@emotion/react";
import ContactUs from "../ContactUs/ContactUs";
import { makeStyles } from "@mui/styles";
import benefit1 from "../../assets/updated/hive-captain/benefit1.svg";
import benefit2 from "../../assets/updated/hive-captain/benefit2.svg";
import benefit3 from "../../assets/updated/hive-captain/benefit3.svg";
import benefit4 from "../../assets/updated/hive-captain/benefit4.svg";
import SubscribeForm from "../Subscribe";
import { decodeJWT, getCaptainBeeByEmail, loginWithToken } from "../../services/api";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  benefitCardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "1380px",
    padding: "20px",
    margin: "150px auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "50px",
    },
    "& > div": {
      flex: 1,
    },
  },
  benefitTextContainer: {
    textAlign: "left",
    "& h4": {
      fontSize: "18px",
    },
    "& h3": {
      fontSize: "62px",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    "& ul li": {
      fontSize: "16px",
      "& span": { fontSize: "26px", fontWeight: "bold" },
    },
  },
  howItWorksheading: {
    width: "100%",
    margin: "100px 0px 30px 0px !important",
    fontSize: "50px !important",
    fontWeight: "600 !important",
    color: theme.palette.text.primary,
    textAlign: "center",
  },
}));

const Franchise = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [seltheme, setSeltheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

   const location = useLocation();
    const defaultSignInToken = new URLSearchParams(location.search).get('signInToken');
  
    useEffect(() => {
      const redirectFlag = localStorage.getItem('redirected');
      if (defaultSignInToken && !redirectFlag) {
        checkLogin(defaultSignInToken);
      }
    }, [defaultSignInToken]);
  
    const checkLogin = async (signInToken) => {
      try {
        const res = await loginWithToken(signInToken);
        if (res.status === 200) {
          const resObj = await decodeJWT(res.data.access_token);
          localStorage.setItem('email', resObj?.email);
          localStorage.setItem('user', resObj?.email);
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
          localStorage.setItem('userType', resObj?.userType);
          localStorage.setItem('redirected', 'true');
  
          if (resObj?.userType === 'CaptainBee') {
            const resObj2 = await getCaptainBeeByEmail(resObj?.email);
            const username = resObj2?.data.Username;
            localStorage.setItem('username', username);
          }
          window.location.reload();
        } else {
          console.error('Login failed:', res.data);
        }
      } catch (err) {
        console.error('Error during login:', err);
      }
    };

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setSeltheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <SubHeader />
      <Bees />
      <Box mt={30}>
        <h3 className={classes.howItWorksheading}>Benefits of Hive Captain</h3>
        <Box className={classes.benefitCardContainer}>
          <Box>
            <img src={benefit1} />
          </Box>

          <Box className={classes.benefitTextContainer}>
            <h4>Revenue and Earnings Opportunities: </h4>
            <h3>Captains earn multiple income streams:</h3>

            <ul>
              <li>
                <span>5% commission</span> on Indexx products purchased by their
                Hive Members.
              </li>
              <li>
                <span>10% commission</span> share from monthly growth in their
                Hive Members’ portfolios.
              </li>
              <li>
                Referral bonuses for bringing new participants into the Hive
                program.
              </li>
            </ul>
          </Box>
        </Box>
        <Box className={classes.benefitCardContainer}>
          <Box className={classes.benefitTextContainer}>
            <h4>Performance-Based </h4>
            <h3>Rewards:</h3>

            <ul>
              <li>
                Monthly Bonuses in INEX Tokens: Additional INEX token bonuses
                based on their performance, calculated by the total profit of
                all Members they guide.
              </li>
              <li>
                Ranking Tiers: Rewards increase with ranks from Copper to Gold
                based on total Hive profit, providing substantial earning
                potential for high-performing Captains.
              </li>
            </ul>
          </Box>

          <Box>
            <img src={benefit2} />
          </Box>
        </Box>
        <Box className={classes.benefitCardContainer}>
          <Box>
            <img src={benefit3} />
          </Box>

          <Box className={classes.benefitTextContainer}>
            <h4>Access to </h4>
            <h3> Exclusive Resources:</h3>

            <ul>
              <li>
                Training: Captains participate in regular training sessions
                covering crypto markets, sales techniques, and Hive management.{" "}
              </li>
              <li>
                Support Team: Dedicated team support and a resource library to
                help manage Hive Members effectively.{" "}
              </li>
            </ul>
          </Box>
        </Box>

        <Box className={classes.benefitCardContainer}>
          <Box className={classes.benefitTextContainer}>
            <h4>Direct Influence on </h4>
            <h3> Hive Success: </h3>

            <ul>
              <li>
                Captains guide their Members’ investment decisions, aligning
                their earnings with the Hive’s success, creating a strong
                incentive for Captains to drive positive outcomes for Members.
              </li>
            </ul>
          </Box>

          <Box>
            <img src={benefit4} />
          </Box>
        </Box>
      </Box>
      {/* <ContactUs /> */}
      <SubscribeForm />
    </>
  );
};

export default Franchise;
