import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Button, InputAdornment } from "@mui/material";
import subscribeImg from "../assets/updated/subscribeImg.svg";
import MessageIcon from "@mui/icons-material/Message";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    maxWidth: "980px",
    width: "100%",
    margin: "300px auto 0px auto",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      flex: "1",
    },
  },
  image: {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  contentContainer: {
    textAlign: "center",
    "& h5": {
      fontSize: "32px",
      fontWeight: "bold",
      marginBottom: "15px",
    },
    "& p": {
      fontSize: "16px",
      marginBottom: "10px",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      flex: "1",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
}));

const SubscribeForm = () => {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [error, setError] = useState("");
  const handleSubscribe = async () => {
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    setError("");

    // API endpoint and payload
    const baseUrl = "https://api.indexx.ai";
    const apiUrl = `${baseUrl}/api/v1/inex/basic/emailsubscribe`;
    const payload = {
      email,
      website: "hive",
    };

    try {
      // Send POST request using axios
      const response = await axios.post(apiUrl, payload);
      if (response.status === 201) {
        setEmail(""); // Clear the email field
      } else {
        console.log(`Subscription failed: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Subscription Error:", error);
    }
  };

  return (
    <div className={classes.root}>
      {/* Image Section */}
      <div className={classes.imageContainer}>
        <img src={subscribeImg} alt="Placeholder" className={classes.image} />
      </div>

      {/* Content Section */}
      <div className={classes.contentContainer}>
        <h5>Don’t Miss Our New Recent Updates!</h5>
        <p>
          Subscribe to our email updates to know latest happenings in crypto and
          the Hive
        </p>
        <div className={classes.formContainer}>
          <TextField
            variant="outlined"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MessageIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary">
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              onClick={handleSubscribe}
            >
              Subscribe
            </span>
          </Button>
        </div>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default SubscribeForm;
