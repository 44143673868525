import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import HeaderNew from "./Components/Header/HeaderNew";
import HeaderTop from "./Components/HeaderTop";
import Signup from "./Components/Sign-up/Signup";
import Franchise from "./Components/Franchise/Franchise";
import First from "./Components/First/First";
// import HoneyJar from './Components/HoneyJar/HoneyJar';
import HoneyJarB2C from "./Components/HoneyJarB2C/HoneyJarB2C";
// import Bees from './Components/CaptainBees/CaptainBees';
// import HoneyComb from './Components/HoneyComb/HoneyComb';
// import Resource from './Components/Resource/Resource';
// import Seminar from './Components/Resource/Seminar';
// import Process from './Components/Process/Process';
import ComingSoon from "./Components/ComingSoon/ComingSoon";
import HoneyBees from "./Components/HoneyBees/HoneyBees";
import { ThemeProvider } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import ComingSoonPage from "./Components/ComingSoon/ComingSoonPage";
import Policies from "./Components/Footer/Policies";
import PublicProfile from "./Components/PublicProfile/PublicProfile";
import ReferralPage from "./Components/CaptainBees/ReferralPage";
import ThemeContextUpdated from "./utils/ThemeContextUpdated";
import NotFound from "./pages/NotFound";

function App() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <ThemeContextUpdated>
      <div className="App">
        <BrowserRouter>
          <HeaderTop />
          {/* <HeaderNew /> */}
          <Routes>
            <Route path="/" element={<First />} />
            <Route path="/sign-up/*" element={<Signup />} />
            <Route path="/hive-captain" element={<Franchise />} />
            <Route path="/hive-member" element={<HoneyBees />} />
            <Route path="/jar" element={<HoneyJarB2C />} />
            {/* <Route path='/process' element={<Process />}/> */}
            <Route path="/captainbee/:name" element={<PublicProfile />} />
            <Route path="/coming-soon" element={<ComingSoonPage />} />
            <Route path="/policy" element={<Policies />} />
            <Route path="/view-ref" element={<ReferralPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </ThemeContextUpdated>
  );
}

export default App;
