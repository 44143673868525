import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import frame from "../../assets/landpage/frame.svg";
// import abcd from "../../assets/abcd.jpg";
import "./HoneyBees.css";

import { baseCEXURL, decodeJWT, getAllAffiliateUser, getCaptainBeeByEmail, loginWithToken } from "../../services/api";

import BeeHeader from "./BeeHeader";
import { useTheme } from "@emotion/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import hiveMemberIcon from "../../assets/yellow-hive/hive member icon.svg";
import hiveMemberBigIcon from "../../assets/updated/hive-member/hiveMemberBigLogo.svg";
import hiveBg from "../../assets/updated/hive-captain/hive bg.png";
import { makeStyles } from "@mui/styles";

import logo1 from "../../assets/updated/hive-member/hiveMember1.svg";
import logo2 from "../../assets/updated/hive-member/hiveMember2.svg";
import logo3 from "../../assets/updated/hive-member/hiveMember3.svg";
import leadImage from "../../assets/updated/hive-member/hiveMember.svg";

import benefit1 from "../../assets/updated/hive-member/benefit1.svg";
import benefit2 from "../../assets/updated/hive-member/benefit2.svg";
import benefit3 from "../../assets/updated/hive-member/benefit3.svg";
import benefit4 from "../../assets/updated/hive-member/benefit4.svg";
import SubscribeForm from "../Subscribe";
// Define the makeStyles hook
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1980px",
    margin: "80px auto",
    width: "100%",
    padding: "20px",
  },

  heroHeaderContainer: {
    display: "flex",
    gap: "40px",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  backgroundImg: {
    position: "absolute",
    right: 0,
    // zIndex: 1,
    display: "flex",
    justifyContent: "flex-end",
    "& img": {
      width: "85%",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  imgContainer: {
    [theme.breakpoints.down("md")]: {
      width: "80%",
      margin: "auto",
      "& img": {
        width: "100%",
      },
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    maxWidth: "500px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      alignItems: "center",
    },
  },
  heading: {
    fontSize: "74px",
    fontWeight: "bold",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "52px",
    },
  },
  description: {
    fontSize: "18px",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  buttonContainer: {
    flex: 3,
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flex: 3,
      marginTop: "20px",
      flexDirection: "column",
      width: "100%",
      gap: "50px",
    },
  },
  button: {
    backgroundColor: "#FFB300",
    borderRadius: "2px",
    color: "#282828",
    py: 1,
    textTransform: "none",
    fontSize: "13px",
    fontWeight: "600",
    boxShadow: "none",
    mt: 2,
    "&:hover": {
      backgroundColor: "#FFD000",
      boxShadow: "none",
    },
  },
  howItWorksContainer: {
    maxWidth: "1280px",
    width: "100%",
    margin: "250px auto 100px auto",
  },
  howItWorksheading: {
    width: "100%",
    margin: "100px 0px 30px 0px !important",
    fontSize: "52px !important",
    fontWeight: "600 !important",
    color: theme.palette.text.primary,
    textAlign: "center",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr ",
    },
  },

  card: {
    flex: "1 1 30%", // Adjust the basis value to control how many cards are shown per row
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "24px",
    textAlign: "left",
  },
  logo: {
    // Add your logo styles here
    width: "96px",
    height: "96px",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  cardHeading: {
    marginTop: "24px !important",
    marginBottom: theme.spacing(1),
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: theme.palette.text.primary,
  },
  cardText: {
    fontSize: "14px !important",
    marginTop: "8px !important",
    color: theme.palette.text.secondary,
  },
  startNowRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "200px auto",
    "& h2": {
      fontSize: "62px",
      fontWeight: "600",
      color: theme.palette.text.primary,
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        fontSize: "42px",
        margin: "150px auto",
      },
    },
  },
  leadContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "600px",
    margin: "100px auto",
    padding: "10px",
    alignItems: "center",
    flexDirection: "column",
    "& img": {
      height: "150px",
      margin: "auto",
      marginBottom: "20px",
    },
    "& h3": {
      fontSize: "52px",
      fontWeight: "600",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    "& p": {
      fontSize: "16px",
      marginBottom: "20px",
      color: theme.palette.text.secondary,
    },
  },
  benefitCardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "1380px",
    margin: "150px auto",
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "50px",
    },
    "& > div": {
      flex: 1,
    },
  },
  benefitTextContainer: {
    textAlign: "left",
    "& h4": {
      fontSize: "18px",
    },
    "& h3": {
      fontSize: "62px",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "42px",
      },
    },
    "& ul li": {
      fontSize: "16px",
      "& span": { fontSize: "26px", fontWeight: "bold" },
    },
  },
}));

const HoneyBees = () => {
  const [topRatedCbees, setTopRatedCBees] = useState();
  const navigate = useNavigate();
  const classes = useStyles();
  const [themes, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "light"
  );

  const location = useLocation();
  const defaultSignInToken = new URLSearchParams(location.search).get('signInToken');

  useEffect(() => {
    const redirectFlag = localStorage.getItem('redirected');
    if (defaultSignInToken && !redirectFlag) {
      checkLogin(defaultSignInToken);
    }
  }, [defaultSignInToken]);

  const checkLogin = async (signInToken) => {
    try {
      const res = await loginWithToken(signInToken);
      if (res.status === 200) {
        const resObj = await decodeJWT(res.data.access_token);
        localStorage.setItem('email', resObj?.email);
        localStorage.setItem('user', resObj?.email);
        localStorage.setItem('access_token', res.data.access_token);
        localStorage.setItem('refresh_token', res.data.refresh_token);
        localStorage.setItem('userType', resObj?.userType);
        localStorage.setItem('redirected', 'true');

        if (resObj?.userType === 'CaptainBee') {
          const resObj2 = await getCaptainBeeByEmail(resObj?.email);
          const username = resObj2?.data.Username;
          localStorage.setItem('username', username);
        }
        window.location.reload();
      } else {
        console.error('Login failed:', res.data);
      }
    } catch (err) {
      console.error('Error during login:', err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getAllAffiliateUser().then((data) => {
      // Define the names of the users you want to filter
      const targetNames = [
        "Queen Bee",
        "Grandma Honey",
        "Golden Sea Sailing",
        "Blockmichael22",
      ];

      // Map over targetNames to preserve the order and find corresponding users
      const orderedFilteredUsers = targetNames
        .map((name) => data.find((user) => user.accname === name))
        .filter((user) => user !== undefined); // Filter out undefined entries (if any user is not found)

      // Set the ordered filtered users to state
      setTopRatedCBees(orderedFilteredUsers);
    });
  }, []);

  return (
    <>
      {/* <LandHeader /> */}
      <BeeHeader />
      <Box className={classes.container}>
        <Box className={classes.heroHeaderContainer}>
          <div className={classes.backgroundImg}>
            <img src={hiveBg} />
          </div>
          <div className={classes.imgContainer}>
            <img src={hiveMemberBigIcon} />
          </div>
          <div className={classes.textContainer}>
            <p className={classes.heading}>Hive Member</p>
            <p className={classes.description}>
              Represents the core membership within the Indexx Hive network,
              contributing to the community's growth and success in the crypto
              industry.
            </p>
            <Button
              variant="contained"
              disableTouchRipple
              className={classes.button}
              onClick={() =>
                window.open(
                  "https://indexx.ai/auth/signup-email?redirectWebsiteLink=exchange",
                  "_blank"
                )
              }
            >
              <span style={{ textTransform: "capitalize" }}>
                Sign Up as Hive Member
              </span>
            </Button>
          </div>
        </Box>

        <Box className={classes.howItWorksContainer}>
          <Typography className={classes.howItWorksheading}>
            How it Works
          </Typography>

          <div className={classes.gridContainer}>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo1} />
              </div>
              <Typography className={classes.cardHeading}>
                1. Sign Up as Hive Member
              </Typography>
              <Typography className={classes.cardText}>
                Become a Hive Captain! Subscribe with $200 monthly and lead the
                community with exclusive INEX rewards.
              </Typography>
            </Box>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo2} />
              </div>
              <Typography className={classes.cardHeading}>
                2. Select A Hive Captain
              </Typography>
              <Typography className={classes.cardText}>
                Refer friends to join and unlock exclusive rewards! The more
                friends you bring, the more you earn together.
              </Typography>
            </Box>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo3} />
              </div>
              <Typography className={classes.cardHeading}>
                3. Learn The Platform
              </Typography>
              <Typography className={classes.cardText}>
                Earn 5% commission on purchases by your Hive Members and 10%
                profit share from their monthly portfolio growth!
              </Typography>
            </Box>
          </div>
        </Box>

        <Box className={classes.startNowRoot}>
          <h2>Start Learning Now</h2>

          <Button
            variant="contained"
            disableTouchRipple
            className={classes.button}
            onClick={() =>
              window.open(
                "https://indexx.ai/auth/signup-email?redirectWebsiteLink=exchange",
                "_blank"
              )
            }
          >
            <span style={{ textTransform: "capitalize" }}>
              Sign Up as Hive Member
            </span>
          </Button>
        </Box>

        <Box className={classes.leadContainer}>
          <img src={leadImage} />
          <h3>Thriving.Engaging</h3>
          <p>
            Not assuming leadership responsibilities like Hive Captain, Hive
            member actively participate in trading activities and engage with
            the community. They benefit from access to investment algorithms,
            learning resources, and simplified investment options offered
            through Hive Packs. Additionally, Honey Bees follow the guidance of
            Hive Captain, ensuring efficient hive activities and successful
            investment options. Through their dedication and collaboration
            within the Indexx ecosystem, Hive members contribute to the
            collective advancement of decentralized finance.
          </p>
          <Button
            variant="contained"
            disableTouchRipple
            className={classes.button}
            onClick={() =>
              window.open(
                "https://indexx.ai/auth/signup-email?redirectWebsiteLink=exchange",
                "_blank"
              )
            }
          >
            <span style={{ textTransform: "capitalize" }}>
              Sign Up as Hive Member
            </span>
          </Button>
        </Box>
        <Box mt={30}>
          <h3 className={classes.howItWorksheading}>Benefits of Hive Member</h3>
          <Box className={classes.benefitCardContainer}>
            <Box>
              <img src={benefit1} />
            </Box>

            <Box className={classes.benefitTextContainer}>
              <h4>Access to </h4>
              <h3>Expert Guidance:</h3>

              <ul>
                <li>
                  <span>Personalized Mentorship:</span> Members receive
                  one-on-one mentoring from their Hive Captain, providing
                  insights into crypto investments and market trends.
                </li>
                <li>
                  <span>1Learning Opportunities:</span> Members benefit from
                  educational resources provided by Captains, including guides,
                  workshops, and tailored investment strategies.
                </li>
              </ul>
            </Box>
          </Box>
          <Box className={classes.benefitCardContainer}>
            <Box className={classes.benefitTextContainer}>
              <h4>Enhanced </h4>
              <h3>Investment Opportunities:</h3>

              <ul>
                <li>
                  <span>Hive Packs: </span>
                  Members can choose from pre-packaged investment options like
                  Power Packs, Crypto Packs, and Action Packs, each designed to
                  maximize profit potential based on the Member’s investment
                  level.
                </li>
                <li>
                  <span>Optimized Portfolio Growth:</span>
                  Each pack is structured to balance potential gains with risk,
                  ensuring new investors can enter the market confidently.
                </li>
              </ul>
            </Box>

            <Box>
              <img src={benefit2} />
            </Box>
          </Box>
          <Box className={classes.benefitCardContainer}>
            <Box>
              <img src={benefit3} />
            </Box>

            <Box className={classes.benefitTextContainer}>
              <h4>Community and </h4>
              <h3>Support:</h3>

              <ul>
                <li>
                  <span>Collaborative Environment: </span>
                  Members can connect with other users within the Hive platform,
                  fostering a supportive and collaborative environment to learn
                  and grow{" "}
                </li>
                <li>
                  <span>Structured Investment Plans:</span>
                  Members follow a structured plan with monthly additions and
                  adjustments to portfolios, aiding long-term growth.
                </li>
              </ul>
            </Box>
          </Box>

          <Box className={classes.benefitCardContainer}>
            <Box className={classes.benefitTextContainer}>
              <h4>Risk Reduction and </h4>
              <h3>Performance Monitoring: </h3>

              <ul>
                <li>
                  <span>Portfolio Management: </span>
                  With Captain guidance, Members avoid common investment
                  mistakes, effectively reducing risks associated with volatile
                  crypto markets.
                </li>
                <li>
                  <span>Performance Tracking: </span>
                  Members can view portfolio performance through the platform,
                  seeing growth trends, portfolio adjustments, and any
                  commissions earned by their Captain as motivation.
                </li>
              </ul>
            </Box>

            <Box>
              <img src={benefit4} />
            </Box>
          </Box>
        </Box>
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: 10,
              fontSize: `${isMobile ? "28px" : "50px"}`,
              fontWeight: 600,
            }}
          >
            Trending Hive Captains
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: "60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Grid
                container
                // columns={{ xs: 1, sm: 12, md: 12 }}
                spacing={{ xs: 10, md: 2 }}
                maxWidth={"840px"}
              >
                {topRatedCbees?.map((item) => (
                  <Grid item xs={12} sm={12} md={3}>
                    <>
                      <Link
                        to={`/captainbee/${item.Username}`}
                        style={{
                          textDecoration: "none",
                          color: "var(--body_color)",
                        }}
                      >
                        <Stack
                          direction="column"
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            sx={{
                              width: "171px",
                              height: "169px",
                              // backgroundImage: `url(${frame})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              position: "relative",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                              // border:"none"
                            }}
                          >
                            <div
                              style={{
                                width: "171px",
                                height: "169px",
                                position: "absolute",
                                zIndex: "1",
                              }}
                            >
                              <img
                                src={frame}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            {item?.photoIdFileurl === null ||
                              item?.photoIdFileurl === undefined ? null : (
                              <Box className="hexagon">
                                <img
                                  alt=""
                                  src={item?.photoIdFileurl}
                                  // src={(item?.photoIdFileurl === undefined || item?.photoIdFileurl === null) ? frame : item?.photoIdFileurl}
                                  width={"63px"}
                                  height={"66px"}
                                  ml={"-6px"}
                                  border={"none"}
                                />
                              </Box>
                            )}
                          </Box>

                          <Typography
                            variant="text"
                            fontSize={isMobile ? "15px" : "20px"}
                            fontWeight={700}
                            mt={2}
                          >
                            {item?.accname}
                          </Typography>
                          <Typography
                            variant="text"
                            fontSize={isMobile ? "11px" : "13px"}
                            fontWeight={400}
                            mt={1}
                            mb={3}
                          >
                            {item?.firstname + " " + item?.lastname}
                          </Typography>
                        </Stack>
                      </Link>
                    </>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#FFB300",
                borderRadius: "2px",
                color: "#282828",
                width: `${isMobile ? "65%" : "280px"}`,
                px: 3,
                py: 1,
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 600,
                boxShadow: "none",
                mt: 3,
                "&:hover": {
                  backgroundColor: "#FFD000",
                  boxShadow: "none",
                },
              }}
            >
              <a
                href="/bees"
                style={{
                  textDecoration: "none", // Set textDecoration to "none" here
                  color: "#282828", // Add the color style for the anchor tag
                }}
              >
                Select your Hive Captain Now!
              </a>
            </Button>
          </Box>

          <SubscribeForm />
        </div>
      </Box>
    </>
  );
};

export default HoneyBees;
