import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>What is Hive?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: "left" }}>
            Hive is a decentralized blockchain platform that allows users to
            create, share, and curate content while earning cryptocurrency
            rewards. How do I earn rewards on Hive? Users earn rewards through
            content creation, curation, and engagement. The more interaction and
            value your content generates, the higher the potential rewards in
            the form of Hive cryptocurrency.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>How do I get started on Hive?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: "left" }}>
            To get started on Indexx Hive, you first need to create an account.
            You can explore various listings, content, and opportunities within
            the platform. Indexx Hive is designed to help users engage with
            decentralized projects, create content, and curate posts. As you
            participate, you can earn Hive tokens based on your contributions.
            Stay active by connecting with other users, following creators, and
            sharing your own work to build your presence within the community.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>What is a Hive Captain?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: "left" }}>
            Is a central figure within the Indexx Hive network, tasked with
            leading and mentoring a team of Hive member in the crypto industry.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>What is a Hive Member</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: "left" }}>
            Represents the core membership within the Indexx Hive network,
            contributing to the community's growth and success in the crypto
            industry.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>What is the Hive community like?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: "left" }}>
            The Hive community is a decentralized platform where users create,
            share, and curate content, earning rewards in Hive tokens. It's a
            diverse, collaborative space that values freedom of expression,
            transparency, and empowering creators.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
