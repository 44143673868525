import React, { useEffect, useRef, useState } from "react";
import CrossIcon from "../../assets/headerTop/cross";
import Logo from "../../assets/headerTop/new indexx.ai logo.svg";
import "./styles.css";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import DarkMode from "../DarkMode/DarkMode";
import Data, { auth_header_data } from "./data";
import { Link } from "react-router-dom";
import frame from "../../assets/updated/header/captain.png";
import beeframe from "../../assets/updated/header/normal.png";
import dummy from "../../assets/updated/header/dummy.png";
import CrossIconNew from "../../assets/updated/cross_white.png";

import {
  getCaptainBeeStatics,
  getHoneyUserDetails,
  getCaptainBeeByEmail,
  checkByemail,
  getUserShortToken,
  decodeJWT,
} from "../../services/api";

const poppins = {};

const getAuthenticatedUrl = async (url) => {
  const isAuthenticated = localStorage.getItem("access_token");
  const email = localStorage.getItem("email");
  let shortToken;

  if (email) {
    shortToken = await getUserShortToken(email);
  } else if (isAuthenticated) {
    let decodedValue = await decodeJWT(isAuthenticated);
    shortToken = await getUserShortToken(decodedValue?.email);
  }

  if (isAuthenticated) {
    const urlObj = new URL(url, window.location.origin);
    urlObj.searchParams.set("signInToken", shortToken?.data);

    // Check if the URL is for the stock-token page
    if (url.includes("/update/home/stock-token")) {
      urlObj.searchParams.set("buyToken", "AMZN");
    } else if (url.includes("/update/home")) {
      urlObj.searchParams.set("buyToken", "INEX");
    }

    return urlObj.toString();
  }

  return url;
};


const updateProfileUrlsWithToken = async (data) => {
  const updatedData = await Promise.all(
    data.map(async (item) => {
      if (item.mainTextDesktop === "profile") {
        item.href = await getAuthenticatedUrl(item.href);
        if (item.dropDownContent) {
          for (const content of item.dropDownContent) {
            for (const link of content.links) {
              link.href = await getAuthenticatedUrl(link.href);
            }
          }
        }
      }
      return item;
    })
  );
  return updatedData;
};

const HeaderTest = () => {
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const { pathname } = useLocation();
  const [authHeader, setAuthHeader] = useState(auth_header_data);
  const themes = useTheme();
  const elementRef = useRef(null);
  const timeoutRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [headerData, setHeaderData] = useState(Data);
  const [backdropVisibility, setBackdropVisibility] = useState(false);
  //
  const [loading, setLoading] = useState(true);

  const [userProfile, setUserProfile] = useState();
  const [profileLoading, setProfileLoading] = useState(true);

  const [isCaptain, setisCaptain] = useState(false);

  const isAuthenticated = localStorage.getItem("access_token") !== null;

  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "light"
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  //
  useEffect(() => {
    console.log("theme_value", theme);
  }, [theme]);
  //
  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    const email = localStorage.getItem("email"); // Assuming you also store email
    setIsLoggedIn(!!accessToken && !!refreshToken && !!email);
  }, []);

  useEffect(() => {
    async function checkUserType() {
      let userType =
        localStorage.getItem("userType") !== undefined
          ? String(localStorage.getItem("userType"))
          : undefined;
      let username =
        localStorage.getItem("username") !== undefined
          ? String(localStorage.getItem("username"))
          : undefined;

      const user =
        localStorage.getItem("user") !== undefined
          ? String(localStorage.getItem("user"))
          : undefined;
      let getUserType = await checkByemail(String(user));
      userType = getUserType.userType;
      const accessToken =
        localStorage.getItem("access_token") !== undefined
          ? String(localStorage.getItem("access_token"))
          : undefined;
      // debugger;
      if (userType === "CaptainBee") {
        if (accessToken && accessToken !== "null" && accessToken !== "") {
          //debugger;
          let resObj = await getCaptainBeeByEmail(String(user));
          console.log("resObj", resObj);
          username = resObj?.data.Username;
        }
        setisCaptain(true);
        setLoading(false);
        if (username) {
          getCaptainBeeStatics(String(username)).then((data) => {
            setUserProfile(data?.data?.affiliateUserProfile?.photoIdFileurl);
            setProfileLoading(false);
          });
        }
      } else {
        setisCaptain(false);
        setLoading(false);
        getHoneyUserDetails(String(user)).then((data) => {
          setUserProfile(data?.data?._doc?.profilePic);
          setProfileLoading(false);
        });
      }
    }
    checkUserType();
  }, []);

  // const handleLogin = (isLogin) => {
  //   if (!isLogin) return;

  //   setModalShow(true);
  // };
  // const handleLogout = (isLogout) => {
  //   if (!isLogout) return;
  //   localStorage.removeItem("access_token");
  //   localStorage.removeItem("refresh_token");
  //   localStorage.removeItem("email");
  //   localStorage.removeItem("userType");
  //   setIsAuthenticated(false);
  //   setIsLoggedIn(false);
  // };
  // const handleItemClick = (path, i) => {
  //   setactiveIndex(i);
  //   window.location.href = path;
  // };
  const handleMouseEnter = () => {
    // Set a timeout to open the dropdown after 0.2s
    timeoutRef.current = setTimeout(() => {
      setBackdropVisibility(true);
    }, 200);
  };

  useEffect(() => {
    const updateHeader = async () => {
      const updatedAuthData = await updateProfileUrlsWithToken(auth_header_data);
      setAuthHeader(updatedAuthData);
    };

    updateHeader();
  }, [isAuthenticated]);

  const handleMouseLeave = () => {
    // Clear the timeout if user leaves before 0.2s
    clearTimeout(timeoutRef.current);
    // Hide the dropdown after a delay
    setTimeout(() => {
      setBackdropVisibility(false);
    }, 200);
  };
  const updateBackDropVisibility = (type) => {
    if (type === "enter") handleMouseEnter();
    if (type === "leave") handleMouseLeave();
  };
  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const computedStyle = window.getComputedStyle(element);
      const visibility = computedStyle.getPropertyValue("visibility");
      const display = computedStyle.getPropertyValue("display");

      if (visibility === "hidden" || display === "none") {
        console.log("Element is hidden or not visible");
      } else {
        console.log("Element is visible");
        setBackdropVisibility(false);
      }
    }
  }, []);
  const handleLogout = (e, nm) => {
    if (nm !== "logout") return;
    logOutUser(e);
  };
  const logOutUser = (e) => {
    e.preventDefault();
    const userType =
      localStorage.getItem("userType") !== undefined
        ? String(localStorage.getItem("userType"))
        : undefined;
    localStorage.removeItem("user"); //remove one item
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("redirected");
    localStorage.removeItem("email");
    localStorage.clear(); //clear all localstorage
    console.log(userType);
    window.location.href = `https://indexx.ai/auth/login?action=Logout`;
  };

  const userEmail = localStorage.getItem("user") || "";

  return (
    <>
      <nav
        style={{ position: "fixed", top: 0, left: 0, zIndex: 10000 }}
        className={poppins.className}
      >
        <div className="wrapper">
          {!isMob ? (
            <div
              className="backdrop"
              style={{
                display: "block",
                opacity: backdropVisibility ? 1 : 0,
                background: theme === "dark" ? "rgba(0,0,0,0.5)" : "",
                transitionDelay: ".1s",
                height: backdropVisibility ? "100vh" : 0,
              }}
            ></div>
          ) : (
            ""
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",

              flex: 1,
            }}
          >
            <div className="logo" style={{ marginRight: "30px" }}>
              <a href="https://indexx.ai" style={{ display: "flex" }}>
                {/* <Logo /> */}
                <img src={Logo} alt="" />
              </a>
            </div>
            <input type="radio" name="slider" id="menu-btn" />
            <input type="radio" name="slider" id="close-btn" />
            <ul className="nav-links" style={{ flex: 1 }}>
              <label htmlFor="close-btn" className="btn close-btn">
                <img src={CrossIconNew} alt="cross icon " />
              </label>
              {isMob &&
                authHeader
                  .filter((el) => el.isAuth === isAuthenticated)
                  .map((element, i) => (
                    <>
                      {element.mainTextDesktop === "Logout" ||
                      element.mainTextMob === "Logout" ? (
                        <li
                          className="main"
                          style={{
                            marginLeft: i === 0 ? "auto" : "",
                            display: "flex",
                            cursor: "pointer",
                          }}
                        >
                          <button
                            style={{
                              border: "none",
                              background: "none",
                              marginLeft: "20px",
                            }}
                            onClick={(e) => handleLogout(e, "logout")}
                          >
                            Logout
                          </button>
                        </li>
                      ) : (
                        <li
                          className="main"
                          style={{
                            marginLeft: i === 0 ? "auto" : "",
                          }}
                        >
                          <a
                            href={element.href}
                            className={`desktop-item ${
                              element.active ? "link_active" : ""
                            }`}
                          >
                            {isAuthenticated
                              ? userEmail
                              : element.mainTextDesktop}
                          </a>
                          <input type="checkbox" id={element.mainTextDesktop} />
                          {isAuthenticated ? (
                            <label
                              htmlFor={element.mainTextMob}
                              className="mobile-item"
                            >
                              {isAuthenticated
                                ? userEmail
                                : element.mainTextDesktop}
                              {element.hasMegaDrop ? " >" : ""}
                            </label>
                          ) : (
                            <a href={element.href} className="mobile-item">
                              {isAuthenticated
                                ? userEmail
                                : element.mainTextDesktop}
                              {element.hasMegaDrop}
                            </a>
                          )}

                          {element.hasMegaDrop ? (
                            <div
                              className="mega-box"
                              style={{
                                background:
                                  theme?.palette?.mode === "light"
                                    ? "#FAFAFC"
                                    : "",
                                color:
                                  theme?.palette?.mode === "light"
                                    ? "#333336 !important"
                                    : "",
                              }}
                              ref={elementRef}
                            >
                              <div className="content">
                                {element.dropDownContent.map((elem) => (
                                  <div
                                    className="row"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <header>{elem?.heading}</header>
                                    <ul
                                      className={`mega-links ${
                                        elem?.mainList ? "main" : ""
                                      }`}
                                    >
                                      {elem?.links.map((el) => (
                                        <li>
                                          <a
                                            onClick={(e) =>
                                              handleLogout(
                                                e,
                                                el.name.toLocaleLowerCase()
                                              )
                                            }
                                            href={el.href}
                                            className={
                                              theme?.palette?.mode === "light"
                                                ? "dark_color"
                                                : ""
                                            }
                                          >
                                            {el.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}

                                <div className="row"></div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      )}
                    </>
                  ))}

              {headerData.map((element) => (
                <>
                  <li
                    className="main"
                    onMouseEnter={() =>
                      updateBackDropVisibility(
                        !element.hasMegaDrop ? "" : "enter"
                      )
                    }
                    onMouseLeave={() =>
                      updateBackDropVisibility(
                        !element.hasMegaDrop ? "" : "leave"
                      )
                    }
                  >
                    <a
                      href={element.href}
                      className={`desktop-item ${
                        element.active ? "link_active" : ""
                      } ${poppins.className}`}
                      onMouseEnter={() =>
                        updateBackDropVisibility(
                          !element.hasMegaDrop ? "" : "enter"
                        )
                      }
                    >
                      {element.mainTextDesktop}
                    </a>
                    {element.hasMegaDrop ? (
                      <>
                        <input type="checkbox" id={element.mainTextMob} />

                        <label
                          htmlFor={element.mainTextMob}
                          className="mobile-item"
                        >
                          {element.mainTextDesktop}{" "}
                          {element.hasMegaDrop ? ">" : ""}
                        </label>
                      </>
                    ) : (
                      <a href={element.href} className="mobile-item">
                        {element.mainTextMob}
                      </a>
                    )}

                    {element.hasMegaDrop ? (
                      <div
                        className="mega-box"
                        ref={elementRef}
                        style={{
                          background: theme === "light" ? "#FAFAFC" : "",
                          color: theme === "light" ? "#333336" : "white",
                          paddingLeft: "65px",
                        }}
                      >
                        <div className="content">
                          {element.dropDownContent.map((elem, i) => (
                            <div
                              key={i}
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <header
                                style={{
                                  color: theme === "light" ? "#A0A0A5" : "",
                                  textAlign: "left",
                                }}
                                className={`${poppins.className}`}
                              >
                                {elem.heading}
                              </header>
                              <ul
                                className={`mega-links ${
                                  elem.mainList ? "main" : ""
                                }`}
                              >
                                {elem.links.map((el, i) => (
                                  <li
                                    key={i}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",

                                      textAlign: "left",
                                    }}
                                    className="profile-list-item"
                                  >
                                    <a
                                      href={el.href}
                                      style={{
                                        color:
                                          theme === "light"
                                            ? "#333336"
                                            : "white",
                                        textAlign: "left",
                                      }}
                                      className={` ${poppins.className}`}
                                    >
                                      {el.name}
                                    </a>
                                    <div
                                      className={`profile-inner-item-border-bottom `}
                                      style={{
                                        background: "green",
                                        width: "16px",
                                        height: "2px",
                                        marginTop: "-5px",
                                      }}
                                    ></div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}

                          <div className="row"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {element.hasDrop ? (
                      <ul class="drop-menu">
                        <li>
                          <a href="#">Drop menu 1</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 2</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 3</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 4</a>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                </>
              ))}

              {!isMob &&
                authHeader
                  .filter((el) => el.isAuth === isAuthenticated)
                  .map((element, i) => (
                    <>
                      {element.mainTextDesktop === "Logout" ||
                      element.mainTextMob === "Logout" ? (
                        <li
                          className="main"
                          style={{
                            marginLeft: i === 0 ? "auto" : "",
                            display: "flex",
                            cursor: "pointer",
                            color: "white",
                          }}

                          // onClick={(e) =>
                          //   handleLogout(
                          //     e,
                          //     element.mainTextDesktop.toLocaleLowerCase()
                          //   )
                          // }
                        >
                          <a onClick={(e) => handleLogout(e, "logout")}>
                            Logout
                          </a>
                        </li>
                      ) : (
                        <li
                          className="main"
                          style={{
                            marginLeft: i === 0 ? "auto" : "",
                            display: "flex",
                          }}
                          // onMouseEnter={
                          //   isAuthenticated
                          //     ? () => updateBackDropVisibility("enter")
                          //     : () => updateBackDropVisibility("leave")
                          // }
                          // onMouseLeave={() => updateBackDropVisibility("leave")}
                        >
                          {isAuthenticated && !loading && (
                            <div
                              style={{
                                marginBottom: "-83px",
                                zIndex: "10000000",

                                transform: "translateY(10px)",
                              }}
                            >
                              <div
                                style={{
                                  width: isCaptain ? "60px" : "50px",
                                  height: isCaptain ? "70px" : "60px",
                                  // backgroundImage: `url(${
                                  //   isCaptain ? frame : beeframe
                                  // })`,
                                  transform: !isCaptain ? "rotate(-30deg)" : "",
                                  // backgroundImage: `url(${frame})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "center",
                                  position: "relative",
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "center",
                                  // border:"none"
                                }}
                              >
                                <div
                                  style={{
                                    width: isCaptain ? "60px" : "50px",
                                    height: isCaptain ? "70px" : "60px",
                                    position: "absolute",
                                    zIndex: "1",
                                  }}
                                >
                                  <img
                                    src={isCaptain === true ? frame : beeframe}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                </div>

                                {!profileLoading && (
                                  <div
                                    className={
                                      isCaptain ? "bee-hexagon" : "elipse-img"
                                    }
                                    style={{
                                      marginBottom: `${
                                        isCaptain === true ? 0 : "7px"
                                      }`,
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={userProfile ? userProfile : dummy}
                                      width={"63px"}
                                      height={"66px"}
                                      style={{
                                        border: "none",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <Link
                            to={element.href}
                            className={`desktop-item ${
                              element.active ? "link_active" : ""
                            }`}
                          >
                            {isAuthenticated
                              ? userEmail
                              : element.mainTextDesktop}
                          </Link>
                          <input type="checkbox" id={element.mainTextDesktop} />

                          <a href={element.href} className="mobile-item">
                            {isAuthenticated
                              ? userEmail
                              : element.mainTextDesktop}
                          </a>

                          {element.hasMegaDrop ? (
                            <div
                              className="mega-box"
                              style={{
                                background: theme === "light" ? "#FAFAFC" : "",
                                color:
                                  theme === "light" ? "#333336 !important" : "",
                              }}
                              ref={elementRef}
                            >
                              <div className="content">
                                {element.dropDownContent.map((elem) => (
                                  <div
                                    className="row"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <header
                                      style={{
                                        color:
                                          theme === "light"
                                            ? "#A0A0A5 !important"
                                            : "",
                                      }}
                                    >
                                      {elem?.heading}
                                    </header>
                                    <ul
                                      className={`mega-links ${
                                        elem?.mainList ? "main" : ""
                                      }`}
                                    >
                                      {elem?.links.map((el) => (
                                        <li className="profile-list-item">
                                          <a
                                            onClick={(e) =>
                                              handleLogout(
                                                e,
                                                el.name.toLocaleLowerCase()
                                              )
                                            }
                                            href={el.href}
                                            className={
                                              theme === "light"
                                                ? "dark_color"
                                                : ""
                                            }
                                          >
                                            {el.name}
                                          </a>
                                          <div
                                            className={`profile-inner-item-border-bottom `}
                                            style={{
                                              background: "#11BE6A",
                                              width: "16px",
                                              height: "3px",
                                              marginTop: "-5px",
                                            }}
                                          ></div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}

                                <div className="row"></div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      )}
                    </>
                  ))}
            </ul>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DarkMode />
            <label htmlFor="menu-btn" className="btn menu-btn">
              <CrossIcon />
            </label>
          </div>
        </div>
      </nav>
      {/* {headerData.find(
        (el) => el.active === true && el.hasMegaDrop === true
      ) ? (
        <div
          className="secondary_header_root"
          style={{ background: theme === "dark" ? "#000" : "" }}
        >
          <div className="secondary_header_content">
            {headerData
              .find((el) => el.active === true)
              ?.dropDownContent.find((elem) => elem.mainList === true)
              ?.links?.map((curr, i) => (
                <div
                  key={i}
                  className={`secondary_header_content_item ${
                    i === activeIndex ? "active" : ""
                  }`}
                  onClick={() => handleItemClick(curr.href, i)}
                >
                  <span className="secondary_header_content_img_container">
                    <img
                      src={theme === "light" ? curr?.imgLight : curr?.imgDark}
                    />
                  </span>
                  <span>{curr.name}</span>
                </div>
              ))}
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default HeaderTest;
